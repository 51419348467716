import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header from 'components/layout/Headers/Secondary'
import LogBuildingContent from 'components/layout/LogBuilding/Post/Content'
import LogBuildingCTA from 'components/layout/LogBuilding/Post/CTA'

import type { LogBuildingTemplateQuery } from 'types/graphql'
import type Image from 'types/image'

const LogBuildingTemplate: React.FC<PageProps<LogBuildingTemplateQuery>> = ({
  data,
}) => {
  const POST = data?.post?.logBuildingFields
  const POST_SEO = data?.post?.seo
  const POST_DATE = new Date(data?.post?.dateGmt)

  const INVESTMENT = data?.post?.logBuildingCategories?.nodes![0]!

  if (!POST || !POST_SEO) throw new Error("CMS data didn't load properly")

  const POST_GALLERY: Image[] = useMemo(
    () =>
      POST.logBuildingS2Gallery?.map((slide) => ({
        src: slide?.logBuildingS2GalleryElementImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: slide?.logBuildingS2GalleryElementImg?.altText!,
      })) || [],
    []
  )

  return (
    <Layout>
      <SEO seo={POST_SEO} />
      <Breadcrumbs
        crumbs={[
          { name: 'Dziennik budowy', to: '/dziennik-budowy' },
          { name: INVESTMENT.name! },
          { name: POST.logBuildingS1Title! },
        ]}
      />
      <Header
        title={POST.logBuildingS1Title!}
        date={POST_DATE}
        image={{
          src: POST.logBuildingS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: POST.logBuildingS1Img?.altText!,
        }}
      />
      <main>
        <LogBuildingContent
          content={POST.logBuildingS2Content!}
          gallery={POST_GALLERY}
        />
        <LogBuildingCTA
          image={{
            src: POST.logBuildingS3Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: POST.logBuildingS3Img?.altText!,
          }}
          title={POST.logBuildingS3Title!}
          link={POST.logBuildingS3Cta?.url!}
          linkText={POST.logBuildingS3Cta?.title!}
        />
      </main>
    </Layout>
  )
}

export default LogBuildingTemplate

export const query = graphql`
  query LogBuildingTemplate($locale: String, $databaseId: Int!) {
    post: wpLogBuilding(databaseId: { eq: $databaseId }) {
      seo {
        ...WpSEO
      }
      dateGmt(locale: $locale)
      logBuildingCategories {
        nodes {
          name
        }
      }
      logBuildingFields {
        logBuildingS1Title
        logBuildingS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        logBuildingS2Content
        logBuildingS2Gallery {
          logBuildingS2GalleryElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        logBuildingS3Title
        logBuildingS3Cta {
          url
          title
        }
        logBuildingS3Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
