import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'
import { Heading } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Button from 'components/shared/Button'

import type Image from 'types/image'

type Props = {
  image: Image
  title: string
  link: string
  linkText: string
}

const Section = styled.section`
  background: ${({ theme }) => theme.colors.white50};
  padding: 1rem 0 3rem;
  ${Col} {
    position: relative;
    background: ${({ theme }) => theme.colors.white};
    padding: 0;
  }
  ${({ theme }) => theme.media.md.min} {
    padding: 4rem 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
  ${({ theme }) => theme.media.md.min} {
    padding: 2rem;
  }
`

const Line = styled.span`
  display: block;
  width: 50px;
  height: 1px;
  background: ${({ theme }) => theme.colors.primary};
  margin: 2rem 0;
`

const ImageWrapper = styled.div`
  height: 300px;
  ${({ theme }) => theme.media.md.min} {
    height: 640px;
  }
`

const LogBuildingCTA: React.FC<Props> = ({ image, title, link, linkText }) => {
  return (
    <Section>
      <Container slim>
        <Row alignItems="stretch">
          <Col xs={24} md={12}>
            <Content>
              <Line />
              <div>
                <Heading
                  as="h3"
                  size={40}
                  sizeDiff={0.7}
                  weight={500}
                  margin="1.5rem"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <Button width="230px" as={LocalizedLink} to={link}>
                  {linkText}
                </Button>
              </div>
              <Line />
            </Content>
          </Col>
          <Col xs={24} md={12}>
            <ImageWrapper>
              <LazyImage src={image.src} alt={image.alt ?? ''} fill />
            </ImageWrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default LogBuildingCTA
