/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { darken } from 'polished'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

export type IconButtonProps = {
  icon: string
  background?: CSSProperties['backgroundColor']
  tag?: keyof JSX.IntrinsicElements
  [x: string]: any
}

const ArrowButtonComponent = styled(Button)<
  Pick<IconButtonProps, 'background'>
>`
  width: 50px;
  height: 50px;
  pointer-events: all;
  padding: 0;

  background: ${({ background }) => background ?? 'transparent'};
  border-color: ${({ background }) => background ?? 'rgba(255, 255, 255, 0.6)'};
  &:hover {
    background: ${({ background }) =>
      background ? darken(0.1, String(background)) : 'transparent'};
    border-color: ${({ theme, background }) =>
      background ? darken(0.1, String(background)) : theme.colors.white};
  }
`

const IconButton: React.FC<IconButtonProps> = ({ icon, tag, ...props }) => (
  // @ts-ignore
  <ArrowButtonComponent as={tag} white outline {...props}>
    <Icon white src={icon} size={16} />
  </ArrowButtonComponent>
)

export default IconButton
