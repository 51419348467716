import React from 'react'
import styled, { css } from 'styled-components'

import { Content } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import Gallery from 'components/layout/LogBuilding/Post/Gallery'

import type Image from 'types/image'

type Props = {
  gallery: Image[]
  content: string
}

const Section = styled.article<{ hasGallery: boolean }>`
  position: relative;
  padding: 2rem 0;
  ${({ hasGallery }) =>
    hasGallery &&
    css`
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20%;
        background: ${({ theme }) => theme.colors.white50};
      }
    `}
`

const ContentWrapper = styled.div`
  position: relative;
  padding: 5rem 1.5rem;
  background: ${({ theme }) => theme.colors.white50};
  z-index: 1;
  ${({ theme }) => theme.media.lg.max} {
    padding: 3rem 1.5rem;
  }
  ${({ theme }) => theme.media.sm.max} {
    padding: 1.5rem;
  }
`

const ContentInnerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  b,
  strong {
    font-weight: 500;
  }
`

const GalleryWrapper = styled.div<{ hasGallery: boolean }>`
  position: relative;
  padding: 3rem 0 0;
  ${({ hasGallery }) =>
    hasGallery &&
    css`
      &:before {
        content: '';
        position: absolute;
        bottom: 110px;
        left: 0;
        right: 0;
        height: 100%;
        background: ${({ theme }) => theme.colors.primary};
      }
    `}
`

const LogBuildingContent: React.FC<Props> = ({ content, gallery }) => {
  return (
    <Section hasGallery={gallery.length > 0}>
      <Container slim>
        <ContentWrapper>
          <ContentInnerWrapper>
            <Content
              as="article"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </ContentInnerWrapper>
        </ContentWrapper>
      </Container>
      <GalleryWrapper hasGallery={gallery.length > 0}>
        <Gallery gallery={gallery} />
      </GalleryWrapper>
    </Section>
  )
}

export default LogBuildingContent
