import React from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { Heading, Text, WithLine } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import LazyImage from 'components/shared/LazyImage'
import ArrowButton from 'components/shared/Button/Arrow'
import Button from 'components/shared/Button'

import formatDate from 'utils/formatDate'
import scrollToSection from 'utils/scrollToSection'

import useSlider from 'hooks/useSlider'

import type Image from 'types/image'

type Props = {
  title: string
  image: Image | Image[]
  text?: string
  date?: Date
  button?: {
    link: string
    text: string
  }
}

const Wrapper = styled.header`
  position: relative;
  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 9rem;
  }
  ${({ theme }) => theme.media.xxl.min} {
    margin-bottom: 5rem;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.dark};
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.md.min} {
    padding: 1rem 0 2rem;
    flex-direction: row;
    align-items: center;
    min-height: 430px;
  }
  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 0;
    min-height: 530px;
    padding: 1.5rem 0 3rem;
  }
`

const Line = styled.span`
  display: block;
  width: 50px;
  height: 1px;
  background: ${({ theme }) => theme.colors.primary};
  margin: 2rem 0;
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 260px;
  padding: 2rem 0;
  ${({ theme }) => theme.media.md.min} {
    width: 50%;
    padding-right: 3rem;
    & > div {
      padding-left: 2rem;
    }
    ${({ theme }) => theme.media.xxl.max} {
      width: 60%;
      padding-right: 2rem;
    }
  }
`

const Images = styled.div`
  position: relative;
  height: 300px;
  ${({ theme }) => theme.media.md.min} {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 100%;
    ${({ theme }) => theme.media.xxl.max} {
      width: 40%;
    }
  }
`

const ImageWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.6s;
  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

const Controls = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 3rem;
  right: 3rem;
  ${({ theme }) => theme.media.md.max} {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
  padding: 0 !important;
`

const Header: React.FC<Props> = ({
  title,
  text,
  date,
  image,
  button,
  children,
}) => {
  const images: Image[] = Array.isArray(image) ? image : [image]
  const { activeSlide, goNext, goPrev } = useSlider(images)

  return (
    <Wrapper>
      <InnerWrapper>
        <Container slim>
          <ContentWrapper>
            {date ? (
              <WithLine>
                <Text as="time" weight={300} themecolor="white100">
                  {formatDate(date)}
                </Text>
                <Heading
                  size={50}
                  sizeDiff={0.7}
                  weight={500}
                  themecolor="white"
                  margin="0"
                  line="1.1"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </WithLine>
            ) : (
              <>
                <Line />
                <Heading
                  size={60}
                  weight={700}
                  themecolor="white"
                  margin="0"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {text && (
                  <Heading
                    as="p"
                    size={26}
                    sizeDiff={0.2}
                    weight={400}
                    themecolor="white100"
                    margin="0"
                    line="1.1"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )}
                {!!(button && button.link && button.text) && (
                  <ButtonWrapper>
                    <Button
                      as={LocalizedLink}
                      to={button.link}
                      onClick={(e: React.MouseEvent) => {
                        if (button.link.includes('#')) {
                          e.preventDefault()
                          scrollToSection(button.link)
                        }
                      }}
                    >
                      {button.text}
                    </Button>
                  </ButtonWrapper>
                )}
                <Line />
              </>
            )}
          </ContentWrapper>
        </Container>
        <Images>
          {images.map(({ src, alt }, index) => (
            <ImageWrapper active={index === activeSlide} key={index}>
              <LazyImage src={src} alt={alt ?? ''} fill />
            </ImageWrapper>
          ))}
          {images.length > 1 && (
            <Controls>
              <ArrowButton onClick={goPrev} direction="left" />
              <ArrowButton onClick={goNext} direction="right" />
            </Controls>
          )}
        </Images>
      </InnerWrapper>
      {children}
    </Wrapper>
  )
}

export default Header
