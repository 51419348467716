/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from 'components/shared/LazyImage'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import useBreakpoint from 'hooks/useBreakpoint'
import useOutsideClick from 'hooks/useOutsideClick'

import type Image from 'types/image'

import closeIcon from 'assets/icons/close.svg'
import arrowIcon from 'assets/icons/arrow-right.svg'

const Overlay = styled.div<{ active: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const SwiperWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  margin: auto 15px;
  ${({ theme }) => theme.media.lg.min} {
    margin: auto 40px;
  }
  ${({ theme }) => theme.media.xxl.min} {
    margin: auto 80px;
  }
`

const SwiperInnerWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 250px;
  max-width: 1500px;
  cursor: grab;
  & > div {
    height: 100%;
  }
  @media (min-height: 350px) {
    height: 300px;
  }
  ${({ theme }) => theme.media.sm.min} {
    width: 75vw;
    height: 75%;
  }
  ${({ theme }) => theme.media.md.min} {
    height: 100%;
  }
`

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const CloseBtnWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 15px;
  z-index: 1;
  img {
    filter: brightness(0);
  }
  ${({ theme }) => theme.media.sm.min} {
    top: 0;
    right: 0;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 50%;
  left: 5px;
  right: 5px;
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  ${Button} {
    pointer-events: all;
    &:nth-child(2) {
      transform: rotate(180deg);
    }
  }
  ${({ theme }) => theme.media.sm.max} {
    margin-top: 150px;
  }
`

type Props = {
  close: () => void
  slides: Image[]
  activeIndex: number
  speed?: number
  visible: boolean
}

const ModalCarousel: React.FC<Props> = ({
  close,
  slides,
  activeIndex,
  speed = 850,
  visible,
}) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const sliderRef = useRef<HTMLDivElement>(null)

  const { sm } = useBreakpoint()
  useOutsideClick({
    ref: sliderRef,
    handler: close,
    condition: visible,
    preventClass: 'prevent',
  })

  const goPrev = (e: React.MouseEvent) => {
    e.stopPropagation()
    swiper?.slidePrev()
  }

  const goNext = (e: React.MouseEvent) => {
    e.stopPropagation()
    swiper?.slideNext()
  }

  useEffect(() => {
    swiper?.slideToLoop(activeIndex)
  }, [activeIndex])

  return (
    <Overlay active={visible}>
      <SwiperWrapper>
        <SwiperInnerWrapper ref={sliderRef}>
          {slides && (
            <Swiper
              onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
              slidesPerView={1}
              loop
              speed={speed}
              initialSlide={activeIndex}
            >
              {slides.map(({ src, alt }, index) => (
                <SwiperSlide key={`${alt}-image${index}`}>
                  <SlideWrapper>
                    <LazyImage src={src} alt={alt || ''} fill />
                  </SlideWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </SwiperInnerWrapper>
        <Controls>
          <Button
            round
            roundSize={sm ? '70px' : '46px'}
            white
            onClick={goPrev}
            aria-label="slide left"
            className="prevent"
          >
            <Icon src={arrowIcon} alt="arrow-left" />
          </Button>
          <Button
            round
            roundSize={sm ? '70px' : '46px'}
            white
            onClick={goNext}
            aria-label="slide right"
            className="prevent"
          >
            <Icon src={arrowIcon} alt="arrow-right" />
          </Button>
        </Controls>
        <CloseBtnWrapper>
          <Button
            white
            round
            roundSize={sm ? '60px' : '46px'}
            onClick={close}
            aria-label="close"
          >
            <Icon src={closeIcon} alt="close" size={18} />
          </Button>
        </CloseBtnWrapper>
      </SwiperWrapper>
    </Overlay>
  )
}

export default ModalCarousel
