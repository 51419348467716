import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'
import LazyImage from 'components/shared/LazyImage'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import ModalCarousel from 'components/layout/Carousels/ModalCarousel'

import useBreakpoint from 'hooks/useBreakpoint'

import type Image from 'types/image'

import arrowRight from 'assets/icons/arrow-right-orange.svg'

type Props = {
  gallery: Image[]
  limit?: boolean
}

const Wrapper = styled.div``

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.25s;
  opacity: 0;
  z-index: 2;
  background: ${({ theme }) => rgba(String(theme.colors.primary), 0.6)};
  display: flex;
  justify-content: center;
  align-items: center;
  & > ${Button} {
    pointer-events: none;
  }
`

const SingleImage = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  cursor: pointer;
  &:hover {
    & > ${Overlay} {
      opacity: 1;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
  ${({ theme }) => theme.media.md.min} {
    & > ${Button} {
      height: 60px;
    }
  }
`

const GALLERY_SPEED = 850

const Gallery: React.FC<Props> = ({ gallery, limit }) => {
  const [visibleImages, setVisibleImages] = useState(gallery.length)
  const [modalGallery, setModalGallery] = useState({
    visible: false,
    activeIndex: 0,
    speed: GALLERY_SPEED,
  })

  const { sm, xxl } = useBreakpoint()

  const handleImageClick = (index: number) => {
    setModalGallery({ visible: true, activeIndex: index, speed: 0 })
    setTimeout(
      () =>
        setModalGallery({
          visible: true,
          activeIndex: index,
          speed: GALLERY_SPEED,
        }),
      100
    )
  }

  const closeModal = () => {
    setModalGallery({
      ...modalGallery,
      visible: false,
    })
  }

  useEffect(() => {
    if (limit && sm) {
      if (xxl) {
        setVisibleImages(8)
        return
      }
      if (sm) {
        setVisibleImages(6)
        return
      }
      setVisibleImages(4)
    }
  }, [limit, xxl])

  return (
    <>
      <Wrapper>
        <Container slim>
          <Row alignItems="stretch" gap="1.25rem">
            {gallery.slice(0, visibleImages).map(({ src, alt }, index) => (
              <Col xs={24} sm={12} lg={8} xxl={6} key={index}>
                <SingleImage onClick={() => handleImageClick(index)}>
                  <Overlay>
                    <Button as="div" white round roundSize="75px">
                      <Icon src={arrowRight} size={18} />
                    </Button>
                  </Overlay>
                  <LazyImage src={src} alt={alt ?? ''} fill />
                </SingleImage>
              </Col>
            ))}
          </Row>
          {visibleImages < gallery.length && (
            <ButtonWrapper>
              <Button
                onClick={() => setVisibleImages(gallery.length)}
                width="200px"
              >
                Więcej zdjęć
              </Button>
            </ButtonWrapper>
          )}
        </Container>
      </Wrapper>
      <ModalCarousel
        close={closeModal}
        slides={gallery}
        activeIndex={modalGallery.activeIndex}
        visible={modalGallery.visible}
        speed={modalGallery.speed}
      />
    </>
  )
}

export default Gallery
