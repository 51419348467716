/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'

import IconButton, { IconButtonProps } from 'components/shared/Button/Icon'

import arrowIcon from 'assets/icons/arrow-right.svg'

type Direction = 'left' | 'right' | 'top' | 'bottom'

type ArrowButtonProps = Omit<IconButtonProps, 'icon'> & {
  direction: Direction
}

const ArrowButtonComponent = styled(IconButton)<
  Pick<ArrowButtonProps, 'direction'>
>`
  transform: ${({ direction }) => {
    switch (direction) {
      case 'right':
        return 'rotate(180deg)'
      case 'bottom':
        return 'rotate(-90deg)'
      case 'top':
        return 'rotate(90deg)'
      default:
        return 'rotate(0)'
    }
  }};
`

const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, ...props }) => (
  <ArrowButtonComponent
    white
    outline
    direction={direction}
    {...props}
    icon={arrowIcon}
  />
)

export default ArrowButton
